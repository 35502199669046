<template>
  <table-view
    ref="tableView"
    url="/dispatch/listlogpage"
    :filterable="false"
    :filter-form="dataForm"
    :headers="headers"
    show-index
    single
  >
    <el-form slot="search" @keyup.enter.native="query">
      <el-input v-model="dataForm.siteName" placeholder="工地名称"></el-input>
      <el-input v-model="dataForm.baseName" placeholder="所属基地"></el-input>
      <el-date-picker
        v-model="dataForm.startDispatchTime"
        type="date"
        value-format="timestamp"
        placeholder="任务开始时间"
      ></el-date-picker>
      <el-date-picker
        v-model="dataForm.endDispatchTime"
        type="date"
        value-format="timestamp"
        placeholder="任务结束时间"
      ></el-date-picker>
      <el-button @click="query" type="primary">查询</el-button>
    </el-form>
  </table-view>
</template>

<script>
import TableView from '@/components/templates/table-view'

export default {
  name: 'dispatch-record',

  components: { TableView },

  data () {
    return {
      headers: [
        { id: 1, prop: 'dispatchTime', label: '任务时间', width: 150 },
        { id: 2, prop: 'siteName', label: '工地名称', minWidth: 250 },
        { id: 3, prop: 'constructionPart', label: '施工部位', width: 120 },
        // {
        //   id: 4,
        //   prop: 'dispatchStatus',
        //   label: '状态',
        //   width: 100,
        //   formatter: (row, column, cellValue) => {
        //     // 1 待完成 2 进行中 3 已完成
        //     switch (cellValue) {
        //       case 1:
        //         return '待完成'
        //       case 2:
        //         return '进行中'
        //       case 3:
        //         return '已完成'
        //       case 4:
        //         return '失败'
        //       case 5:
        //         return '待下发'
        //       case 6:
        //         return '待确认'
        //       default:
        //         return '未知'
        //     }
        //   }
        // },
        { id: 5, prop: 'quantityNumber', label: '方量数量', width: 100 },
        { id: 6, prop: 'demandCarSize', label: '需求车辆', width: 100 },
        { id: 9, prop: 'executedCars', label: '派遣车辆', width: 100 },
        { id: 7, prop: 'baseName', label: '所属基地', width: 100 },
        { id: 8, prop: 'executeBaseName', label: '执行基地', width: 100 },
        { id: 9, prop: 'update', label: '执行基地', width: 100 },
        { id: 10, prop: 'executeBaseName', label: '执行基地', width: 100 }
      ],
      dataForm: {
        baseName: '',
        siteName: '',
        startDispatchTime: '',
        endDispatchTime: ''
      }
    }
  },

  methods: {
    query () {
      this.$refs.tableView.queryData()
    }
  }
}
</script>
